@use '~scss/settings' as *;

.navigation-menu {
  @include flex(column, flex-start, flex-start);

  position: relative;
  flex: 0 0 0;
  transition: flex ease-in-out 0.25s;
  z-index: 1000;

  &--visible {
    flex: 0 0 320px;
    @include mobile() {
      @include absolute(top 80px bottom 0 right 0 left 0);
    }
  }

  &__closing-container {
    @include absolute(left 100% top 50%);
    @include flex(row, flex-end, center);
    @include size(40px);
    @include mobile() {
      display: none;
    }

    cursor: pointer;
    border-radius: 50%;
    background-color: rgba(black, 0.5);
    transform: translateX(-50%);

    & svg {
      margin-right: 5px;
      fill: $color-white;
      transition: transform 0.25s;
      transform: rotate(-90deg);
    }

    &--rotated svg {
      transform: rotate(90deg);
    }
  }

  &__container {
    @include font-size(16px, 18px);
    @include flex(column, flex-start, flex-start);
    @include size(320px, 100%);
    @include absolute(right 0 top 0 bottom 0);
    @include mobile() {
      @include absolute(top 0 bottom 0 right 0 left 0);
      @include size(100%);
    }

    flex: 1 1 320px;

    padding: 0;
    overflow-x: hidden;
    background-color: var(--color-theme-background-primary);
    box-shadow: 1px 0 15px $color-gray-82;

    &__bottom-container {
      @include flex(row, flex-start, center);

      flex: 0 0 48px;
      width: 100%;

      &__item {
        @include flex(row, center, center);
        @include size(48px);

        cursor: pointer;
      }

      & svg {
        fill: var(--color-theme-text-primary);
      }
    }
    &__items {
      @include flex(column);

      flex: 1 1 100%;
      width: 100%;

      &__item {
        @include button-reset;
        @include flex(row, flex-start, center);

        position: relative;
        width: 100%;
        padding: 4px 0;
        flex: 0 0 48px;
        cursor: pointer;
        color: var(--color-theme-text-primary);
        transition: background-color 0.25s ease;
        background-color: transparent;

        &--selected {
          @include inverted-border-option(
            var(--color-theme-background-secondary)
          );

          background-color: var(--color-theme-background-secondary);
          border-bottom: 1px solid var(--color-theme-background-secondary);
        }

        &:not(*:last-child) {
          border-bottom: 1px solid var(--color-theme-background-secondary);
        }

        &__icon {
          @include flex(row, center, center);

          height: 100%;
          min-width: 48px;
          width: 48px;

          & i.icon {
            @include flex(row, center, center);
            @include size(100%);
          }
        }

        &__spacer {
          flex: 1 1 100%;
        }

        &__left-triangle {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid blue;
          margin-right: 8px;
        }
      }
    }

    &__sub-menu {
      @include absolute(left 48px);

      width: 0;
      height: 100%;
      transition: 0.25s ease-in-out width;

      &--visible {
        width: calc(100% - 48px);
        border-left: 1px solid var(--color-theme-background-secondary);
      }
    }
  }
}
