@use '~scss/settings' as *;

.notification {
  @include flex(row, center);

  width: 100%;
  padding: 16px;
  min-height: 48px;
  font-size: 16px;

  &:hover {
    background-color: $color-gray-ee;
  }

  &__text {
    flex: 1 1 100%;
    align-self: center;

    &--unread {
      font-weight: bold;
    }

    &--unread > &__additional {
      color: $color-primary;
    }

    &__additional {
      font-size: 13px;
    }
  }

  &__option {
    @include flex(column, center);
    color: $color-primary;
  }
}
