@use '~scss/settings' as *;

html,
body,
#root {
  @include size(100%);
}

body {
  @extend %font;

  margin: 0;
  overscroll-behavior: none;
}

#root {
  @include flex(column);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-text-primary;
}

body a {
  color: $color-primary;
  &:hover {
    color: $color-secondary;
  }
}

.not-found {
  @include flex(row, center, center);

  width: 100%;

  &__card {
    border: solid 1px $color-red;
    border-radius: 8px;
    padding: 48px;

    & h1 {
      color: $color-red;
    }
  }
}

///colors
.blue {
  color: $color-blue;
}
.orange {
  color: $color-orange;
}
.yellow {
  color: $color-yellow;
}
.olive {
  color: $color-olive;
}
.green {
  color: $color-green;
}
.red {
  color: $color-red;
}

.confirmation-message {
  font-style: normal;
}

.super-ego-holding-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-gray-f4;

  .ui.input {
    width: 100%;
  }
}
