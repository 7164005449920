@use '~scss/settings' as *;

.eh-fa {
  @include flex(column);

  &__info {
    font-style: italic;
  }

  &__select-all {
    @include flex(row, flex-start, center);
    margin-bottom: 20px;

    label {
      font-weight: bold !important;
      margin-left: 8px;
    }
  }

  &__company {
    @include flex;

    margin-bottom: 8px;
    min-height: 38px;

    &__field {
      @include flex(row, flex-start, center);

      flex: 1 1 100%;

      > *:not(:first-child) {
        margin-left: 8px;
      }

      &__input {
        width: 100%;
      }
    }
  }
}
