@use './_t-flex' as *;
@use './_t-font-size' as *;
@use './_t-border-radius' as *;
@use './_t-table-loader' as *;
@use './_t-size' as *;
@use '../_breakpoints' as *;
@use '../_variables' as *;
@use '../tools/t-status-shadow' as *;

@mixin private-page($number-of-columns: 7) {
  @include font-size(16px, 24px);

  height: 100%;
  padding: 24px;
  flex: 0 1 100%;
  overflow: auto;

  &__top {
    @include flex(row, space-between, flex-start);

    @include tablet() {
      @include flex(column, space-between, flex-start);
      & > *:not(:first-child) {
        margin-top: 4px;
      }
      & > * {
        width: 100%;
      }
    }
    @include mobile() {
      & > div:first-child > div {
        display: none !important;
      }
      & input {
        @include border-left-radius($border-radius!important);
        border-left-color: rgba(34, 36, 38, 0.15) !important;
      }
    }
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: $border-radius;
    padding: 16px;
    &__right-menu {
      @include flex(row, space-between, flex-start);
      > div {
        width: 300px;
      }
      & > *:not(:first-child) {
        margin-left: 8px !important;
      }
    }
  }

  table {
    display: grid;
    // grid-template-columns: minmax(150px, 2fr) 2fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat($number-of-columns, auto [col-table]);
    overflow: auto;
  }
  thead,
  tbody,
  tr {
    @include tablet() {
      display: contents !important;
    }
    display: contents;
  }
  .ui.table:not(.unstackable) tbody,
  .ui.table:not(.unstackable) tr {
    @include tablet() {
      display: contents !important;
    }
  }

  &__table {
    &--loading {
      @include table-loader();
    }
    .ui.label,
    .ui.labels .label {
      @include flex(row, center, center);
      @include size(100%);

      text-align: center;
    }
  }
  .button-group {
    white-space: nowrap;
    text-align: center;

    & .ui.button {
      margin: 0;
      border-radius: 0;
    }
    & > *:first-child {
      & .ui.button {
        @include border-left-radius($border-radius);
      }
    }
    & > *:last-child {
      & .ui.button {
        @include border-right-radius($border-radius);
      }
    }
  }
  .status-label {
    @include status-shadow;
  }

  .email-cell {
    @include flex(row, space-between, center);

    span {
      margin-bottom: 12px;
    }
  }
}
