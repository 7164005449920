@use '~scss/settings' as *;

.ui.raised.very.padded.segment.section-container {
  width: 600px;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.form-container {
  &__forgot-password {
    display: block;
    font-size: 12px;
  }

  > * {
    margin-bottom: 16px !important;
    width: 100%;
  }

  > :last-child {
    margin-bottom: unset !important;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
  }
}
