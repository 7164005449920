$color-white: #fff;
$color-primary: #3f51b5;
$color-secondary: #1e88e5;
$color-text-primary: #3f51b5;

$color-gray-22: #222;
$color-gray-44: #444;
$color-gray-48: #484848;
$color-gray-4F: #4f4f4f;
$color-gray-56: #565656;
$color-gray-61: #616161;
$color-gray-66: #666;
$color-gray-82: #828282;
$color-gray-99: #999;
$color-gray-b2: #b2b2b2;
$color-gray-aa: #aaa;
$color-gray-bd: #bdbdbd;
$color-gray-E4E6: #e4e6e4;
$color-gray-e7: #e7e7e7;
$color-gray-ee: #eee;
$color-gray-f4: #f4f4f4;
$color-gray-f2f3: #f2f3f7;
$color-gray-f2f3f8: #f2f3f8;
$color-gray-f9f: #f9fafb;
$color-gray-E0: #e0e0e0;

$color-red: red;
$color-blue: blue;
$color-yellow: #fbbd08;
$color-orange: #f2711c;
$color-olive: #b5cc18;
$color-green: #21ba45;

$color-semantic-info-message: #276f86;
$color-semantic-info-header: #0e566c;
