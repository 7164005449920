@use '~scss/settings' as *;

.dropzone {
  padding: 0 16px;

  &__container {
    @include flex(column);

    flex-wrap: wrap;
    padding: 64px;
    width: 100%;
    max-height: 300px;
    border: 1px dashed black;
    border-radius: 8px;
    cursor: pointer;

    &__icon {
      @include flex(row, center);
    }

    p {
      @include flex(row, center);

      font-style: italic;
    }
  }

  &__files-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 1rem;
    padding: 16px 0;

    > div > div {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.4),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      padding: 16px;
      border-radius: 5px;
      display: grid;
      grid-template-columns: 32px auto 16px;
      grid-gap: 4px;
      align-items: center;

      > div {
        @include clip-text();
      }
    }

    &__file-name {
      cursor: pointer;
    }

    &__file-icon {
      cursor: pointer;
    }

    &__close-button {
      @include size(16px);

      position: relative;
      i {
        @include absolute(left 3px top 3px);
      }
    }
  }
}

.image-dropzone {
  text-align: center;
  margin-top: 16px;

  &__container {
    position: relative;
    width: 128px;

    &__image {
      height: 128px;
      width: 128px;
    }

    &__close-button.ui.circular.icon.button.mini {
      @include absolute(right -8px top -8px);

      i {
        @include absolute(left 3px top 3px);
      }
    }
  }
}
