@import '../../../scss/settings';

.label-container {
  @include flex();

  margin-bottom: 4px;
  &__label {
    &-error {
      color: $color-red;
    }
  }
  &__required-star {
    color: $color-red;
    margin-left: 2px;
  }
}
