@use '~scss/settings' as *;

.agent-rating-table {
  &--empty {
    height: 500px;
  }
  &--loading {
    @include table-loader();
  }

  &__header {
    margin: 0;
    & > :first-child {
      margin: 2rem 0 0.5rem;
    }
    & > :last-child {
      margin: 0;
    }
  }
}
