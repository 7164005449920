@import '../../scss/settings';
.form {
  & > *:not(:first-child) {
    margin-top: 16px;
  }
}
.form-actions {
  @include flex(row, flex-end, center);

  flex: 1 1 100%;

  margin: 0;
  margin-top: 16px;
  padding: 16px 0 0;
  border-top: 1px solid rgba($color-gray-22, 0.15);
}
