@use '~scss/settings' as *;

.orientation-table {
  &__actions {
    @include flex(row, center);
    width: 100%;

    .ui.basic.button {
      @include size(38px);
      border-radius: 50%;
      border: 0.5px dashed rgba($color: #000000, $alpha: 0.3);
      box-shadow: none;
      background-color: none;
      &:hover {
        box-shadow: none;
        border-color: rgba($color: #000000, $alpha: 0.5);
      }
    }
  }
  &--loading {
    @include table-loader();
  }
  &__header-cell {
    @include flex(row, space-between);
    .icon.question.circle.outline {
      font-size: 18px;
      color: #fdbb2e;
      margin: 0;
    }
  }
  &__course-cell {
    @include flex(row, space-between, center);
  }
  &__double-check {
    transform: translateX(1px);
    &:hover {
      cursor: pointer;
    }
  }
}
