@use '~scss/settings' as *;

.activity {
  @include private-page;
  @include flex(column, flex-start, center);

  &__container {
    width: 50%;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 1120px) {
      width: 100%;
      border: none;
      box-shadow: none;
    }

    &__header {
      @include flex(row, space-between, flex-start);

      min-height: 44px;
    }

    &__notifications {
      > *:not(:last-child) {
        border-bottom: 2px solid $color-gray-ee;
      }
    }

    &__pagination {
      margin-top: 14px;
    }
  }
}
