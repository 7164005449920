@use '~scss/settings' as *;

.info {
  font-style: italic;
}

.checkbox {
  @include flex(row, flex-start, center);

  > label {
    margin-left: 8px;
  }
}

.checkbox-other {
  @include flex(row, space-between, center);

  min-height: 38px;

  &__input {
    flex: 0 1 50%;
  }
}

.select-all {
  margin-bottom: 20px !important;
  font-weight: bold;
}

.title-for-textarea {
  margin-bottom: 4px !important;
}
