.agent-details {
  margin: 24px;

  & table {
    overflow: visible;

    & .ui.input {
      width: 100%;
    }
    & .ui.search.selection.dropdown {
      width: 100%;
    }
  }
}
