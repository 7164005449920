@use '~scss/settings' as *;

.driver-details {
  @include flex(column, space-between, flex-start);
  @include font-size(16px, 24px);

  height: 100%;
  padding: 24px 48px;
  flex: 0 1 100%;
  overflow: auto;

  &__button-container {
    @include flex(column, center, center);
    width: 100%;
    flex: 0 0 56px;

    & .ui.button {
      @include size(300px, 100%);
      @include font-size(18px, 22px);
      @include mobile() {
        @include size(100%);
      }

      background: $color-primary;
      color: #fff;
      border-radius: $border-radius;

      &:hover,
      &:focus,
      &:active {
        background: $color-secondary;
        color: #fff;
      }
    }
  }
  & table {
    overflow: visible;

    & .ui.input {
      width: 100%;
    }
    & .ui.search.selection.dropdown {
      width: 100%;
    }
  }

  &__links {
    @include flex(column);

    a:hover {
      text-decoration: underline;
    }
  }
}
.icon {
  cursor: pointer;
}

.eh-button {
  box-shadow: none !important;
  margin-bottom: 16px !important;
  padding: 0px !important;

  &:hover {
    text-decoration: underline !important;
  }
}
.ui.fixed.table td.overflow-initial-cell {
  overflow: initial;
}
