@use '../_variables' as *;
@use "./t-position" as*;

@mixin inverted-border-option($color, $width: 48px) {
  &::before,
  &::after {
    content: '';
    background-color: transparent;
    height: 5px;
    width: $width;
    box-shadow: 20px 0px 0 0 $color;
  }
  &::before {
    @include absolute(left 0 top -1px);

    border-bottom-right-radius: $border-radius;
    transform: translateY(-100%);
  }

  &::after {
    @include absolute(left 0 bottom -1px);

    border-top-right-radius: $border-radius;
    transform: translateY(100%);
  }
}
