@import '../../../scss/settings';

.input-container {
  @include flex(column);

  &__label-with-icon {
    display: flex;

    & i.question.field-info-icon {
      margin-left: 4px;
    }
  }

  &__error {
    color: $color-red;
  }
}
