@use '~scss/settings' as *;

.drivers {
  @include private-page(11);

  &__top {
    @include flex(row, space-between, center);
  }

  &--admin {
    @include private-page(12);
  }

  .is-driving {
    text-align: center !important;
    vertical-align: middle !important;
    &__icon {
      @include flex(row, center, center);
      @include size(100%);
      margin: 0;
      border-radius: $border-radius;
      align-items: flex-start;

      &-check {
        color: $color-green;
      }

      &-x {
        color: $color-red;
      }

      > i {
        cursor: auto;
      }
    }
  }

  .employment-history-not-allowed {
    cursor: not-allowed;
  }
}
