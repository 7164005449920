@import '../../scss/settings';

.modal {
  &__content {
    position: relative;
  }
}
.form {
  & > * {
    margin-bottom: 8px;
  }
}
.modal-actions-like-container {
  @include flex(row, flex-end, center);

  flex: 1 1 100%;

  margin: 0;
  margin-top: 16px;
  padding: 16px 0 0;
  border-top: 1px solid rgba($color-gray-22, 0.15);
}
.message-container {
  text-align: left !important;
}
.magic-link-paragraph {
  @include flex(row, space-between);

  width: 100%;
  cursor: pointer;

  & i {
    margin-right: 4px;
  }

  & div {
    @include flex(row, center, center);

    & p {
      margin-top: 0 !important;
    }
  }
}
