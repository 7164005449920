@use '~scss/settings' as *;

.eh-container {
  @include flex(column);

  width: 100%;

  > div {
    @include flex(row, space-between, center);

    > h3 {
      width: 100%;
      margin-bottom: 0;
    }
  }

  &__top {
    @include flex(row, flex-end, center);

    width: 100%;
  }
}

.employment-history {
  @include private-page;

  &__actions {
    text-align: center;
  }
}

.button-group {
  @include flex(row, center);

  & .ui.button {
    margin: 0;
    border-radius: 0;
  }

  & > *:first-child {
    & .ui.button {
      @include border-left-radius($border-radius);
    }
  }

  & > *:last-child {
    & .ui.button {
      @include border-right-radius($border-radius);
    }
  }
}

.status-label {
  @include status-shadow;
}
