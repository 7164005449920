@import '../../scss/settings';

.menu-container {
  @include flex(row, space-between, center);
  flex: 1 1 100%;
  width: 100%;

  & .ui.selection.dropdown {
    height: 100%;
    min-height: 12px;
    height: 100%;
    padding: 12px;
    line-height: 16px;
    font-size: 16px;
    & > .dropdown.icon {
      height: 100%;
      padding: 12px;
    }
  }
  .menu > *:not(:first-child) {
    margin: 0;
  }
}
.ui.pagination.right-menu.menu {
  // height: 40px;
  .pagination-nav-link {
    min-width: 3em;
    text-align: center;
    // align-self: center;
    padding: 8px;
    border-right: 1px solid rgba(34, 36, 38, 0.1);
    color: rgba(34, 36, 38, 0.8);
  }
}
