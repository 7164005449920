@import '../../../scss/settings';
.select-container {
  @include flex(column);
}

.ui.selection.active.dropdown .menu {
  height: fit-content;
}

.menu > *:not(:first-child) {
  margin-left: 0 !important;
}
