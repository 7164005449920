@use '~scss/settings' as *;

.applicant-header {
  @include flex(row, flex-start, center);
  @include size(100%, 64px);

  padding: 16px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: $border-radius;

  &__info {
    @include flex(column, space-between);

    margin-left: 16px;

    h3 {
      margin: 0;
    }
  }
}
