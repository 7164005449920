@use '~scss/settings' as *;

.navigation-sub-menu {
  @include flex(column, flex-start);
  @include size(100%);

  position: relative;
  flex: 1 1 100%;
  padding: 0;
  margin: 0px;
  overflow-x: hidden;
  background-color: var(--color-theme-background-secondary);

  &__header {
    @include flex(row, space-between, center);
    flex: 0 0 48px;
    margin: 0;
    padding: 4px 8px;
    width: 100%;

    & h2 {
      @include font-size(32px, 36px);

      margin: 0;
      overflow-x: hidden;
      color: var(--color-theme-text-primary);
    }
  }

  &__list {
    @include flex(column, flex-start, flex-end);
    @include font-size(16px, 28px);
    @include size(calc(100% - 48px), 100%);

    list-style: none;
    padding: 0;
    margin: 0;
    flex: 1 1 100%;
    width: 100%;

    overflow-y: auto;
    overflow-x: hidden;

    & button {
      color: var(--color-theme-text-primary);
    }

    &__option {
      @include flex(row, flex-start, center);
      white-space: nowrap;

      position: relative;
      flex: 0 0 48px;
      background-color: var(--color-theme-background-primary);
      color: var(--color-theme-text-primary);
      width: 100%;
      padding: 8px 20px;
      overflow: visible;
      cursor: pointer;

      border-left: 4px solid transparent;

      &.route-active {
        cursor: auto;
        color: var(--color-theme-text-primary);
        font-weight: 600;
        border-left: 4px solid var(--color-theme-text-primary);
      }

      &--is-admin {
        flex: 1 1 48px;
      }

      span {
        transition: transform 0.2s;
      }

      &:not(.route-active):hover {
        color: var(--color-theme-text-primary);

        span {
          transform: translate3d(16px, 0, 0);
        }
      }

      &:not(*:last-child) {
        border-bottom: 1px solid var(--color-theme-background-secondary);
      }
    }
    &__company-container {
      display: flex;
      width: 100%;
    }

    &__new-company {
      white-space: nowrap;

      width: 100%;
      background-color: var(--color-theme-background-primary);
      color: var(--color-theme-text-primary);

      .ui.button {
        width: 100%;
        height: 48px;
        text-align: left;
        font-weight: normal;
        background-color: var(--color-theme-background-primary);
        color: var(--color-theme-text-primary);

        &:hover,
        &:focus {
          background-color: var(--color-theme-background-primary);
          color: var(--color-theme-text-primary);
          font-weight: bold;
        }
      }
    }
  }
}
.ui.icon.button.edit-company {
  background-color: var(--color-theme-background-primary);
  color: var(--color-theme-text-primary);
  border-radius: 0;
  height: 44px;
  margin-right: 0;
}
