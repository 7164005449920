@use '~scss/settings' as *;

.profile-update {
  &__profile-image-heading {
    @include flex(row, space-between, center);
  }

  &__avatar {
    @include flex(row, center, center);

    img {
      @include size(200px);

      border-radius: 50%;
    }
  }
}
