@import '../../scss/settings';

.company-image-container {
  @include flex(column, space-between, center);

  > div {
    width: 100%;
  }
  &__photo {
    @include flex(column, center, center);

    margin-top: 24px;

    img {
      @include size(256px, 200px);

      border-radius: 8px;
    }
    .ui.red.button {
      margin-top: 24px;
    }
  }

  &__photo-upload {
    @include flex(row, flex-start, center);

    margin-bottom: 8px;
  }
}

.centered-modal.ui.modal {
  top: 50%;
  transform: translateY(-50%);
}
