@use '~scss/settings' as *;

.change-instructor-modal {
  &__course {
    &__radio-group {
      @include flex(row, space-between, center);

      gap: 12px;
      padding: 0 16px 16px;

      &--less-than-three {
        display: grid;
        grid-template-columns: repeat(3, auto);
      }
    }
  }

  &__content {
    @include flex(row, space-between, center);

    &__replace-icon {
      cursor: auto;
    }

    padding: 16px 16px;

    &__select {
      flex: 0 0 30%;
    }
  }
}
