@use '~scss/settings' as *;

.signatures {
  @include flex(row, space-between);

  margin-bottom: 8px;
  border-bottom: 1px solid rgba($color-gray-22, 0.15);

  .ui.icon.button {
    margin-bottom: 4px;
  }
}

.eh-documents {
  @include flex(column, center, center);

  margin-top: 32px;
}
