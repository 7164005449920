@use '~scss/settings' as *;

.form-checkbox {
  @include flex(row, flex-start, center);

  &__label {
    margin-left: 10px;
  }

  &__input {
    @include size(20px);
  }
}
