@use '~scss/settings' as *;

.home {
  @include flex(column);

  height: 100%;
  flex: 1 1 auto;
  position: relative;

  --header-height: 80px;
  --icon-size: 48px;

  --color-theme-background-primary: #eee;
  --color-theme-background-secondary: #fff;
  --color-theme-text-primary: #333333;
  --color-theme-background-primary-oposite: #333333;

  &.dark {
    --color-theme-background-primary: #{$color-primary};
    --color-theme-background-secondary: #{$color-secondary};
    --color-theme-text-primary: #fff;
    --color-theme-background-primary-oposite: #fff;
  }
  &__content {
    @include font-size(16px, 24px);

    @include flex();

    flex: 1 1 100%;
    overflow: auto;

    &__banner {
      display: flex;

      @include flex(column);
      flex: 1 1 100%;
      overflow: auto;

      &__content {
        @include flex(row, center, center);

        height: 70px;
        min-height: 50px;

        background-color: #ffe8e6;
        margin: 24px 24px 0;
        border-radius: 4px;

        .ui.blue.tiny.button {
          height: 30px;
          width: 70px;
          position: relative;
          margin-left: 28px;

          .ui.loader::after {
            border-color: white transparent transparent;
          }
        }
      }
    }
  }
}
