@use '~scss/settings' as *;

.safety-page {
  @include flex(column);
  width: 100%;

  &__header {
    margin-left: 28px;
    padding-top: 24px;
  }

  &__signed-app-url {
    font-size: 18px;
    font-style: italic;

    margin-left: 32px;
    margin-bottom: 18px;
  }

  &__driver-header {
    padding: 8px;
    margin: 0 28px 16px;
    border: solid 1px;
    border-radius: $border-radius;
    border-color: $color-gray-E4E6;

    &__left {
      @include flex(row, flex-start, center);

      gap: 16px;
      & h3 {
        margin: 0;
      }
    }
  }

  &__roles {
    @include flex(row, space-between, center);
  }

  &__role-header {
    margin: 0;
    font-size: 16px;

    color: #3f51b5;
  }

  &__table {
    @include flex(row, space-between, baseline);
    padding: 0 24px;

    > .ui.table {
      margin: 0 4px;
      overflow: auto;
      width: 50%;
    }
    &__button-group {
      white-space: nowrap;
      @include flex(row, center);

      & .ui.button {
        margin: 0;
        border-radius: 0;
      }
      & > *:first-child {
        & .ui.button {
          @include border-left-radius($border-radius);
        }
      }
      & > *:last-child {
        & .ui.button {
          @include border-right-radius($border-radius);
        }
      }
    }
  }
  &__fuel-number {
    @include phablet() {
      @include flex(column);
    }

    @include flex(row, space-between);
    padding: 28px 24px 0;

    &__left {
      @include flex(row, flex-start, center);
      width: 100%;

      > div {
        position: relative;
        padding-right: 4px;
        .input-container__error {
          @include absolute(top 40px);
          color: $color-red;
          width: 100%;
        }
      }
    }
    &__right {
      @include flex();

      .ui.button {
        width: 200px;
      }
    }
  }

  &__signing-history {
    margin: 20px 24px 32px;

    > p {
      font-style: italic;
    }
  }
}
