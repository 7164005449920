@use '~scss/settings' as *;

.orientation {
  padding: 24px;

  &__top {
    @include flex(row, flex-start, center);

    padding: 16px;
    border: 1px solid rgba(34, 36, 38, 0.15);

    & > *:not(:first-child) {
      margin-left: 32px;
    }
  }
}
