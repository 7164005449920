@use '~scss/settings' as *;

.driver-role-modal {
  &--owner-operator {
    height: 500px;
    overflow: auto;

    &__ifta {
      @include flex(row, space-between, center);

      padding: 0 16px 4px 8px;
    }

    &__personal-info {
      padding: 0 16px 16px;

      > :first-child {
        padding: 0;
      }
    }

    &__label {
      margin: 16px;
      font-size: 16px;
    }

    &__bold {
      font-weight: 700;
    }

    &__title {
      margin: 16px;
      font-size: 20px;
      font-weight: 700;
    }

    &__radio-group {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 12px;

      padding: 0 16px 16px;
    }
  }

  &--equipment-identification {
    @include flex(column, space-between, space-between);

    height: 500px;

    position: relative;
    padding: 16px;

    &__add-equipment {
      padding: 8px;
    }
  }
  &--actions {
    @include flex(row, flex-end, center);

    margin: 0;
    padding: 60px 0 0;
    border-top: 1px solid rgba($color-gray-22, 0.15);
  }
}

.ui.red.mini.icon.right.floated.right.labeled.button.equipment-identification__remove-equipment {
  @include absolute(top 0 right 16px);
}

.ui.green.mini.icon.right.floated.right.labeled.button.equipment-identification__add-equipment {
  @include absolute(bottom 60px right 16px);
}

.scrolling.dimmable > .ui.dimmer {
  justify-content: center;
}
