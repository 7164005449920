@use '~scss/settings' as *;

.eh-page {
  @include font-size(16px, 24px);

  height: 100%;
  padding: 24px;
  flex: 0 1 100%;
  overflow: auto;

  &__header {
    margin-bottom: 14px;
  }
}
