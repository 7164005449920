@import '../../scss/settings';

.header-container {
  @include flex(row, space-between, center);
  @include mobile() {
    padding: 0 12px;
  }
  @include size(100%, var(--header-height));
  min-height: var(--header-height);

  background-color: var(--color-theme-background-primary);
  color: var(--color-theme-text-primary);
  padding: 0 32px 0 32px;
  z-index: 1000;

  &__left {
    @include flex(row, space-between, center);

    & > *:not(*:first-child) {
      margin-left: 32px;
    }
    &__menu-button {
      cursor: pointer;

      & .bar {
        width: 24px;
        height: 4px;
        margin-top: 4px;
        transition: 0.4s transform;
        background-color: var(--color-theme-text-primary);
        border-radius: 4px;
      }

      &--close {
        & .bar1 {
          transform: translate(0, 8px) rotate(-45deg);
        }
        & .bar2 {
          opacity: 0;
        }
        & .bar3 {
          transform: translate(0, -8px) rotate(45deg);
        }
      }

      &--disable {
        pointer-events: none;
      }
    }
    a {
      color: var(--color-theme-text-primary);
      text-decoration: none;
      &:hover {
        color: var(--color-theme-text-primary);
      }
    }
  }
}

.logo {
  @include flex(row, center, center);
  @include mobile() {
    @include font-size(28px, 30px);
  }

  font-size: 2.5rem;
  line-height: 3rem;
  height: 100%;
}

.seh-menu {
  @include flex(row, center, center);
  & label {
    @include font-size(16px, 18px);
  }

  &__filter-menu {
    @include flex(row, space-between, center);
    height: 100%;
    cursor: pointer;

    &__option {
      @include flex(row, center, center);
      height: 100%;
      border-bottom: 4px solid transparent;
      background-color: var(--color-theme-background-primary);
      color: var(--color-theme-text-primary);

      padding: 0 16px;
      transition: background-color 0.4s;
      &--selected,
      &:hover {
        background-color: var(--color-theme-background-secondary);
        border-color: var(--color-theme-text-primary);
        color: var(--color-theme-text-primary);
      }
      &--default-selected {
        background-color: var(--color-theme-background-secondary);
        border-color: var(--color-theme-text-primary);
        color: var(--color-theme-text-primary);
      }
    }
  }
  height: 100%;

  > *:first-child {
    min-width: 80px;
  }

  > *:not(:first-child) {
    padding: 16px;

    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 480px) {
      margin-left: 12px;
    }
  }
}
.user-menu {
  @include flex(row, center, center);

  margin-bottom: 4px;

  > a:first-of-type:hover {
    text-decoration: underline;
  }

  > * {
    color: $color-white !important;
    margin-left: 32px;

    &:hover {
      cursor: pointer;
    }
  }

  > *:first-child {
    margin-left: 0;
  }

  &__user {
    @include flex(row, space-between, center);

    position: relative;

    .icon.user {
      margin-bottom: 8px;
    }

    &:hover {
      text-decoration: underline;
    }

    &__picture {
      @include size(32px);

      border-radius: 50%;
    }

    &__username {
      margin-left: 4px;
    }
  }

  &__badge {
    @include absolute(left -4px top -4px);
    @include size(14px);

    fill: $color-white;

    &--without-image {
      @include absolute(left -7px top -7px);
      @include size(12px);

      fill: $color-white;
    }
  }
}
