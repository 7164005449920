@mixin _position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);
    @if $i
      and $i + 1 <= length($args)
      and (type-of( nth($args, $i + 1) ) == number
      or nth($args, $i + 1) == initial
      or nth($args, $i + 1) == inherit ) {
      #{$o}: nth($args, $i + 1);
    } @else if type-of( nth($args, 1) ) == number {
      #{$o}: nth($args, 1);
    }
  }

  position: $position;
}

@mixin absolute($args) {
  @include _position(absolute, $args);
}

@mixin relative($args) {
  @include _position(relative, $args);
}

@mixin fixed($args) {
  @include _position(fixed,    $args);
}

@mixin sticky($args) {
  @include _position(sticky,    $args);
}
